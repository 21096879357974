import { Footer } from "../Footer";
import { Layout } from "../Layout";
import { Block } from "../blocks/Block";
import { Cookie } from "../blocks/Cookie";
import { graphql } from "gatsby";
import * as React from "react";

export const PageTemplate = ({
  blocks,
  options,
  location,
  siteMeta,
  slug,
  yoast,
}) => {
  if (!blocks) return null;
  var hasHero = false;
  if (
    blocks.map((block) => block.id).indexOf("WpContentNode_Pagefields_Page_Hero") >= 0
  ) {
    hasHero = true;
  } else if (
    blocks.map((block) => block.id).indexOf("WpContentNode_Pagefields_Page_Hero") >= 0
  ) {
    hasHero = true;
  }

  return (
    <Layout blocks={blocks} yoast={yoast} siteMeta={siteMeta} slug={slug}>
      {blocks &&
        blocks.map((block, i) => (
          <Block
            {...block}
            _blocks={blocks}
            key={block.id ? block.id : i}
            options={options}
            resetHero={!hasHero}
          />
        ))}
      <Cookie host={location.host} />
      <Footer
        {...options.contactOptions}
        logotype={options.general.logotypeFooter}
      />
    </Layout>
  );
};

export const query = graphql`
  fragment PageFragment on WpPage {
    title
    pageFields {
      page {
        ...PageHeroBlockFragment
        ...PageCasesBlockFragment
        ...PageCustomersBlockFragment
        ...PageTextBlockFragment
        ...PageOffersBlockFragment
        ...PagePeopleBlockFragment
        ...PageTagsBlockFragment
        ...PageMenuBlockFragment
        ...PageImageVideoBlockFragment
        ...PageBlogPostsBlockFragment
        ...PageFormBlockFragment
        ...PageSwitchCaseBlockFragment
      }
    }
  }
  fragment CaseFragment on WpCase {
    title
    pageFields {
      page {
        ...CaseHeroBlockFragment
        ...CaseCasesBlockFragment
        ...CaseCustomersBlockFragment
        ...CaseTextBlockFragment
        ...CaseOffersBlockFragment
        ...CasePeopleBlockFragment
        ...CaseTagsBlockFragment
        ...CaseMenuBlockFragment
        ...CaseImageVideoBlockFragment
        ...CaseFormBlockFragment
        ...CaseSwitchCaseBlockFragment
      }
    }
  }
`;
