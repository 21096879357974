import { Navbar } from "../Navbar";
import { Seo } from "../blocks";
import "bootstrap/dist/css/bootstrap.css";
import React from "react";

export const Layout = ({ children, blocks, siteMeta, slug, yoast }) => {
  const blockList = blocks || [];
  return (
    <div>
      <Seo key="seo" siteMeta={siteMeta} yoast={yoast} slug={slug} />
      {blocks.length > 0 && <Navbar blocks={blockList} />}
      <div id="innerbody">{children}</div>
    </div>
  );
};
