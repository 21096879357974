import { PageTemplate } from "../components/PageTemplate";
import { withStore } from "../libs/mobx/withStore";
import { graphql } from "gatsby";
import React from "react";

const Case = ({ data, location }) => {
  const {
    wpCase: { pageFields, seo, slug },
    wp: { themeGeneralSettings, generalSettings },
  } = data;

  const blocks = pageFields.page || pageFields.page;
  const options = themeGeneralSettings;
  return (
    <PageTemplate
      blocks={blocks}
      options={options}
      location={location}
      siteMeta={generalSettings}
      yoast={seo}
      slug={slug}
    />
  );
};
// In development, disable seo query to avoid errors
export const pageQuery = graphql`
  query CaseById($id: String!) {
    wpCase(id: { eq: $id }) {
      ...CaseFragment
      slug
      seo {
        title
        metaDesc
        canonical
        opengraphImage {
          sourceUrl
        }
      }
    }
    wp {
      ...FooterFragment
      generalSettings {
        title
        description
        url
      }
    }
  }
`;

export default withStore(Case);
